import React, { useMemo } from 'react';
import { useApi } from 'bb/api/browser/useApi';
import { AmountPerMonth } from 'bb/i18n';
import { formatSubscriptionProducts } from 'bb/subscription/subscriptionUtils';

export const StartAtPrice = () => {
    const { data: subscriptionProducts } = useApi(
        '/api/subscription/products',
        {
            fixed: true
        }
    );

    const lowestPrice = useMemo(
        () =>
            subscriptionProducts &&
            formatSubscriptionProducts(subscriptionProducts)?.lowestPrice,
        [subscriptionProducts]
    );

    return <AmountPerMonth amount={lowestPrice} />;
};
