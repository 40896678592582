import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { MaxWidthContainer } from 'bb/common/Containers';
import commonCss from 'bb/style/common.module.scss';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { Col, Stack } from 'bb/ui';
import { type ColProps } from 'bb/ui/Flex/Row/Col';
import { PaddedBox, type PaddedBoxProps } from '../PaddedBox';
import css from './usp.module.scss';

type UspColProps = {
    bgColor?: Color;
    paddedBoxRef?: PaddedBoxProps['ref'];
    children?: React.ReactNode;
} & Pick<ColProps, 'className'>;

export const UspCol = forwardRef<HTMLDivElement, UspColProps>(
    ({ bgColor, children, paddedBoxRef, className }, ref) => (
        <Col
            ref={ref}
            className={classnames(
                commonCss.col50,
                commonCss.hMax600,
                css.column,
                className
            )}
            style={{
                ...makeCSSVariableFromColor(
                    '--usps-col-background-color',
                    bgColor
                )
            }}
        >
            <PaddedBox ref={paddedBoxRef}>
                <MaxWidthContainer maxWidth={400}>
                    <Stack justifyContent="center">{children}</Stack>
                </MaxWidthContainer>
            </PaddedBox>
        </Col>
    )
);
